import { useEffect, useState } from "react";
import { companies, jobTypes } from "../../data/data";
import { useNavigate, Link } from "react-router-dom";
import { list } from "../../utils/get";
import Layout from "../../components/Layout";

const AnketList = () => {
  const [data, setData] = useState([]);
  const [showList, setShowList] = useState([]);
  const [company, setCompany] = useState("");
  const [jobType, setJobType] = useState("");
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState("");
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setJobs(companies.reduce((acc, cur) => {
      if (cur.slug === user.company) {
        return cur.jobs.reduce((ac, cu) => {
          if (!ac.includes(cu.position)) {
            ac.push(cu.position);
          }
          return ac;
        }, []);
      }
      return acc;
    }, []));
    list(navigate).then((data) => {
      setData(data);
      setShowList(data);
    });
    // eslint-disable-next-line
  }, []);

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
    setJobs(companies.reduce((acc, cur) => {
      if (cur.slug === e.target.value) {
        return cur.jobs.reduce((ac, cu) => {
          if (!ac.includes(cu.position)) {
            ac.push(cu.position);
          }
          return ac;
        }, []);
      }
      return acc;
    }, []));
    let l = [...data];
    if (jobType) {
      l = l.filter((item) => item.emp_work_type === jobType);
    }
    if (job) {
      l = l.filter((item) => item.emp_work_position === job);
    }
    if (e.target.value) {
      l = l.filter((item) => item.company === e.target.value);
    }
    setShowList(l);
  };

  const handleJobTypeChange = (e) => {
    setJobType(e.target.value);
    let l = [...data];
    if (company) {
      l = l.filter((item) => item.company === company);
    }
    if (job) {
      l = l.filter((item) => item.emp_work_position === job);
    }
    if (e.target.value) {
      l = l.filter((item) => item.emp_work_type === e.target.value);
    }
    setShowList(l);
  };

  const handleJobChange = (e) => {
    setJob(e.target.value);
    let l = [...data];
    if (company) {
      l = l.filter((item) => item.company === company);
    }
    if (jobType) {
      l = l.filter((item) => item.emp_work_type === jobType);
    }
    if (e.target.value) {
      l = l.filter((item) => item.emp_work_position === e.target.value);
    }
    setShowList(l);
  }

  return (
    <Layout>
      <div className="fs-75 w-100">
        <div className="ps-4 pe-4 ">
          <div className="filter-container">
            {user && user.userRole === "admin" && (
              <div className="input-container">
                <div className="input-label">Компани:</div>
                <select
                  className="input"
                  onChange={handleCompanyChange}
                >
                  <option value="">Бүгд</option>
                  {companies.map((c) => (
                    <option key={c.slug} value={c.slug}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="input-container">
              <div className="input-label">Ажлын төрөл:</div>
              <select
                className="input"
                onChange={handleJobTypeChange}
              >
                <option value="">Бүгд</option>
                {jobTypes.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-container">
            <div className="input-label">Албан тушаал:</div>
              <select
                className="input"
                onChange={handleJobChange}
              >
                <option value="">Бүгд</option>
                {jobs.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th className="text-center">№</th>
                {user && user.userRole === "admin" && <th>Компани</th>}
                <th>Ажлын төрөл</th>
                <th>Албан тушаал</th>
                <th>Овог</th>
                <th>Нэр</th>
                <th>Хүйс</th>
                <th>Регистр</th>
                <th>Утас</th>
                <th>Өндөр</th>
                <th>Жин</th>
                <th>Имэйл</th>
                <th>Сошиал</th>
                <th>Огноо</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {showList &&
                showList.map((e, index) => (
                    <tr key={e.employee_id}>
                      <td className="text-center">{index + 1}</td>
                      {user && user.userRole === "admin" && (
                        <td>{e.company}</td>
                      )}
                      <td>{e.emp_work_type}</td>
                      <td>{e.emp_work_position}</td>
                      <td>{e.emp_lastname}</td>
                      <td>{e.emp_firstname}</td>
                      <td>{e.emp_sex}</td>
                      <td>{e.emp_register}</td>
                      <td>{e.emp_phone}</td>
                      <td>{e.emp_height}</td>
                      <td>{e.emp_weight}</td>
                      <td>{e.emp_email}</td>
                      <td>{e.emp_social}</td>
                      <td>{e.create_date}</td>
                      <td>
                        <Link
                          to={`/anket/${e.employee_id}?name=${
                            e.emp_firstname + " " + e.emp_lastname
                          }`}
                        >
                          Дэлгэрэнгүй
                        </Link>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default AnketList;
