import { useState } from "react";
import { useParams } from "react-router-dom";
import { AnketContext } from "../../context/Context";
import Step1 from "./step1";
import Step2 from "./step2";
import Success from "./success";
import FormLayout from "../../components/FormLayout";
import { companies } from "../../data/data";

const Form = ({ slug }) => {
  let { step = "1" } = useParams();
  const company = companies.find((company) => company.slug === slug);
  const [anket, setAnket] = useState({
    company: null,
    jobType: null,
    job: null,
    step: step || "1",
  });

  const renderStep = () => {
    switch (step) {
      case "1":
        return <Step1 company={company} />;
      case "2":
        return <Step2 />;
      case "3":
        return <Success />;
      default:
        return <Step1 company={company} />;
    }
  };

  return (
    <>
      <AnketContext.Provider
        value={{
          anket,
          setAnket,
        }}
      >
        <FormLayout>{renderStep()}</FormLayout>
      </AnketContext.Provider>
    </>
  );
};

export default Form;
