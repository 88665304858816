import * as Yup from "yup";

export const FormFullSchema = Yup.object().shape({
  image: Yup.mixed().required("Зураг оруулна уу"),
  lastName: Yup.string()
    .max(50, "Хэтэрхий урт байна")
    .required("Мэдээллээ оруулна уу"),
  firstName: Yup.string()
    .max(50, "Хэтэрхий урт байна")
    .required("Мэдээллээ оруулна уу"),
  registerNumber: Yup.string()
    .min(10, "Регистрийн дугаар буруу байна")
    .max(12, "Хэтэрхий урт байна")
    .required("Мэдээллээ оруулна уу"),
  phoneNumber: Yup.string()
    .max(20, "Утасны дугаар буруу байна")
    .required("Мэдээллээ оруулна уу"),
  email: Yup.string()
    .email("И-мэйл хаяг буруу байна")
    .required("Мэдээллээ оруулна уу"),
  social: Yup.string()
    .max(50, "Хэтэрхий урт байна")
    .required("Мэдээллээ оруулна уу"),
  address: Yup.string()
    .max(100, "Хэтэрхий урт байна")
    .required("Мэдээллээ оруулна уу"),
  gender: Yup.string(),
  height: Yup.number()
    .positive("0-с их тоо оруулна уу")
    .typeError("Тоо оруулна уу")
    .integer(),
  weight: Yup.number()
    .positive("0-с их тоо оруулна уу")
    .typeError("Тоо оруулна уу")
    .integer(),
  yasUndes: Yup.string(),
  tursunGazar: Yup.string().required("Мэдээллээ оруулна уу"),
  ussunGazar: Yup.string().required("Мэдээллээ оруулна уу"),
  heniiGarDeer: Yup.string().required("Мэдээллээ оруулна уу"),
  heduulee: Yup.number()
    .required("Мэдээллээ оруулна уу")
    .positive("0-с их тоо оруулна уу")
    .typeError("Тоо оруулна уу")
    .integer(),
  heddehHuuhed: Yup.number()
    .required("Мэдээллээ оруулна уу")
    .positive("0-с их тоо оруулна уу")
    .typeError("Тоо оруулна уу")
    .integer(),
  zorilgo: Yup.string().required("Мэдээллээ оруулна уу"),
  zarchim: Yup.string().required("Мэдээллээ оруулна уу"),
  baharhal: Yup.string().required("Мэдээллээ оруулна уу"),
  sainZan: Yup.string().required("Мэдээллээ оруулна уу"),
  sulZan: Yup.string().required("Мэдээллээ оруулна уу"),
  hiihDurtai: Yup.string().required("Мэдээллээ оруулна уу"),
  hiihDurgui: Yup.string().required("Мэдээллээ оруулна уу"),
  hamgiinSainHiideg: Yup.string().required("Мэдээллээ оруулна уу"),
  hobby: Yup.string().required("Мэдээллээ оруулна уу"),
  family: Yup.array(
    Yup.object({
      taniiYu: Yup.string().required("Мэдээллээ оруулна уу"),
      ovogNer: Yup.string().required("Мэдээллээ оруулна уу"),
      ajilMergejil: Yup.string().required("Мэдээллээ оруулна уу"),
      ajliinGazar: Yup.string().required("Мэдээллээ оруулна уу"),
      utas: Yup.string().required("Мэдээллээ оруулна уу"),
    }).required()
  ).min(1),
});

export const FormShortSchema = Yup.object().shape({
  image: Yup.mixed().required("Зураг оруулна уу"),
  lastName: Yup.string()
    .max(50, "Хэтэрхий урт байна")
    .required("Мэдээллээ оруулна уу"),
  firstName: Yup.string()
    .max(50, "Хэтэрхий урт байна")
    .required("Мэдээллээ оруулна уу"),
  registerNumber: Yup.string()
    .min(10, "Регистрийн дугаар буруу байна")
    .max(12, "Хэтэрхий урт байна")
    .required("Мэдээллээ оруулна уу"),
  phoneNumber: Yup.string()
    .max(20, "Утасны дугаар буруу байна")
    .required("Мэдээллээ оруулна уу"),
  email: Yup.string()
    .email("И-мэйл хаяг буруу байна")
    .required("Мэдээллээ оруулна уу"),
  social: Yup.string()
    .max(50, "Хэтэрхий урт байна")
    .required("Мэдээллээ оруулна уу"),
  address: Yup.string()
    .max(100, "Хэтэрхий урт байна")
    .required("Мэдээллээ оруулна уу"),
  gender: Yup.string(),
  height: Yup.number()
    .positive("0-с их тоо оруулна уу")
    .typeError("Тоо оруулна уу")
    .integer(),
  weight: Yup.number()
    .positive("0-с их тоо оруулна уу")
    .typeError("Тоо оруулна уу")
    .integer(),
});
