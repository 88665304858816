import { useState } from "react";
import Resizer from "react-image-file-resizer";

const checkExtension = (filename) => {
  const acceptedExtensions = ["jpeg", "jpg", "png"];
  const extension = filename.split(".").pop().toLowerCase();
  return acceptedExtensions.includes(extension);
};

const ImageUploader = ({ handleImage, submitCount, errors }) => {
  const [file, setFile] = useState({
    image: null,
    error: null,
  });

  const handleFileInput = (e) => {
    const maxFileSize = 5 * 1024 * 1024;
    if (e.target.files && e.target.files.length > 0 && e.target.files[0]) {
      if (!checkExtension(e.target.files[0].name)) {
        setFile((prev) => {
          return {
            ...prev,
            error: "Зөвхөн JPG, JPEG, PNG өргөтгөлтэй зураг оруулна уу.",
          };
        });
      } else if (e.target.files[0].size >= maxFileSize) {
        setFile((prev) => {
          return {
            ...prev,
            error: "5MB-аас бага хэмжээтэй зураг оруулна уу.",
          };
        });
      } else {
        Resizer.imageFileResizer(
          e.target.files[0],
          500,
          500,
          "JPEG",
          80,
          0,
          (uri) => {
            setFile((prev) => {
              return { ...prev, image: uri };
            });
            handleImage(uri);
          },
          "file",
          200,
          200
        );
      }
    }
  };

  const handleRemoveImage = () => {
    setFile((prev) => {
      return { ...prev, image: null };
    });
    handleImage(null);
  };

  const renderError = () => {
    if (file && file.error) {
      return <div className="error">{file.error}</div>;
    } else if (submitCount > 0 && errors && errors.image) {
      return <div className="error">{errors.image}</div>;
    }
  };

  return (
    <>
      {file && file.image ? (
        <div
          className="profile-image"
          style={{ backgroundImage: `url(${URL.createObjectURL(file.image)})` }}
          onClick={handleRemoveImage}
        />
      ) : (
        <div className="file-input-container">
          <input type="file" onChange={handleFileInput} />
          {renderError()}
        </div>
      )}
    </>
  );
};

export default ImageUploader;
