export const onImageError = (e, gender) => {
  switch (gender) {
    case "Эрэгтэй":
      e.target.src = process.env.PUBLIC_URL + "/images/male.png";
      break;
    case "Эмэгтэй":
      e.target.src = process.env.PUBLIC_URL + "/images/female.png";
      break;
    default:
      e.target.src = process.env.PUBLIC_URL + "/images/male.png";
      break;
  }
};
