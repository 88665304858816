import React, { useContext, useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import axios from "axios";
import uuid from "react-uuid";
import { useNavigate } from "react-router";
import { format } from "date-fns";
import { AnketContext } from "../../context/Context";
import ImageUploader from "../../components/ImageUploader";
import FormField from "../../components/form/FormField";
import {
  FormFullSchema,
  FormShortSchema,
} from "../../components/form/FormSchema";

const Step2 = () => {
  const navigate = useNavigate();
  const {
    anket,
    anket: { company },
  } = useContext(AnketContext);

  useEffect(() => {
    if (company === null) {
      navigate("/");
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const isFull = () => {
    if (
      anket.company === "vans" ||
      anket.company === "converse" ||
      anket.company === "nike" ||
      anket.company === "turelt"
    ) {
      return true;
    }
    return false;
  };

  let initialValues = {
    image: null,
    lastName: "",
    firstName: "",
    registerNumber: "",
    phoneNumber: "",
    email: "",
    social: "",
    address: "",
    gender: "",
    height: "",
    weight: "",
  };

  if (isFull()) {
    initialValues = {
      ...initialValues,
      yasUndes: "",
      tursunGazar: "",
      ussunGazar: "",
      heniiGarDeer: "",
      heduulee: "",
      heddehHuuhed: "",
      zorilgo: "",
      zarchim: "",
      baharhal: "",
      sainZan: "",
      sulZan: "",
      hiihDurtai: "",
      hiihDurgui: "",
      hamgiinSainHiideg: "",
      hobby: "",
      family: [
        {
          taniiYu: "",
          ovogNer: "",
          ajilMergejil: "",
          ajliinGazar: "",
          utas: "",
        },
      ],
      education: [
        {
          surguuli: "",
          mergejil: "",
          zereg: "",
          dungiinGolch: "",
          elssenOgnoo: "",
          tugssunOgnoo: "",
        },
      ],
      jobExps: [
        {
          companiiNer: "",
          albanTushaal: "",
          garsanShaltgaan: "",
          orsonOgnoo: "",
          garsanOgnoo: "",
        },
      ],
      determinant: [
        {
          ovogNer: "",
          albanBaiguullaga: "",
          albanTushaal: "",
          holbooBarihUtas: "",
          hezeeneesMedeh: "",
        },
        {
          ovogNer: "",
          albanBaiguullaga: "",
          albanTushaal: "",
          holbooBarihUtas: "",
          hezeeneesMedeh: "",
        },
        {
          ovogNer: "",
          albanBaiguullaga: "",
          albanTushaal: "",
          holbooBarihUtas: "",
          hezeeneesMedeh: "",
        },
      ],
      bluemons: [
        {
          ovogNer: "",
          company: "",
          albanTushaal: "",
          holbooBarihUtas: "",
          taniiYu: "",
        },
      ],
    };
  }

  const onSubmit = (values) => {
    try {
      let formData = new FormData();
      const id = uuid();
      const image = new File([values.image], `${id}.JPEG`);
      formData.append("image", image);
      const postData = JSON.stringify({
        employee_id: id,
        company: anket.company,
        emp_lastname: values.lastName,
        emp_firstname: values.firstName,
        emp_register: values.registerNumber,
        emp_phone: values.phoneNumber,
        emp_email: values.email,
        emp_social: values.social,
        emp_address: values.address,
        emp_sex: values.gender,
        emp_height: values.height,
        emp_weight: values.weight,
        emp_work_position: anket.job.position,
        emp_work_type: anket.jobType,
        emp_desired_salary: "",
        emp_chestpic: image.name,
        emp_yas_undes: isFull() ? values.yasUndes : "",
        emp_tursun_gazar: isFull() ? values.tursunGazar : "",
        emp_ussun_gazar: isFull() ? values.ussunGazar : "",
        emp_henii_gar_deer: isFull() ? values.heniiGarDeer : "",
        emp_heduulee: isFull() ? values.heduulee : "",
        emp_heddeh_huuhed: isFull() ? values.heddehHuuhed : "",
        emp_zorilgo: isFull() ? values.zorilgo : "",
        emp_zarchim: isFull() ? values.zarchim : "",
        emp_baharhal: isFull() ? values.baharhal : "",
        emp_sain_zan: isFull() ? values.sainZan : "",
        emp_sul_zan: isFull() ? values.sulZan : "",
        emp_hiih_durtai: isFull() ? values.hiihDurtai : "",
        emp_hiih_durgui: isFull() ? values.hiihDurgui : "",
        emp_hamgiin_sain_hiideg: isFull() ? values.hamgiinSainHiideg : "",
        emp_hobby: isFull() ? values.hobby : "",
        emp_state: "Шинэ",
        emp_state_time: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        emp_family: isFull()
          ? values.family.map((member) => {
              return {
                tanii_yu: member.taniiYu,
                ovog_ner: member.ovogNer,
                ajil_mergejil: member.ajilMergejil,
                ajliin_gazar: member.ajliinGazar,
                utas: member.utas,
              };
            })
          : [],
        emp_education: isFull()
          ? values.education.map((edu) => {
              return {
                surguuli: edu.surguuli,
                mergejil: edu.mergejil,
                zereg: edu.zereg,
                dungiin_golch: edu.dungiinGolch,
                elssen_ognoo: edu.elssenOgnoo,
                tugssun_ognoo: edu.tugssunOgnoo,
              };
            })
          : [],
        emp_jobexps: isFull()
          ? values.jobExps.map((jobExp) => {
              return {
                companii_ner: jobExp.companiiNer,
                alban_tushaal: jobExp.albanTushaal,
                garsan_shaltgaan: jobExp.garsanShaltgaan,
                orson_ognoo: jobExp.orsonOgnoo,
                garsan_ognoo: jobExp.garsanOgnoo,
              };
            })
          : [],
        emp_determinant: isFull()
          ? values.determinant.map((det) => {
              return {
                ovog_ner: det.ovogNer,
                alban_baiguullaga: det.albanBaiguullaga,
                alban_tushaal: det.albanTushaal,
                holboo_barih_utas: det.holbooBarihUtas,
                hezeenees_medeh: det.hezeeneesMedeh,
              };
            })
          : [],
        emp_bluemons: isFull()
          ? values.bluemons.map((bluemon) => {
              return {
                ovog_ner: bluemon.ovogNer,
                company: bluemon.company,
                alban_tushaal: bluemon.albanTushaal,
                holboo_barih_utas: bluemon.holbooBarihUtas,
                tanii_yu: bluemon.taniiYu,
              };
            })
          : [],
      });
      // console.log(postData);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/anket/savefile`, formData)
        .then(() => {
          console.log("File upload successful.");
          axios
            .post(`${process.env.REACT_APP_API_URL}/api/anket`, postData, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then(() => {
              console.log("Submit successful.");
              navigate("/step/3");
            });
        })
        .catch((error) => {
          alert("File upload error", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="form-container">
      <Formik
        initialValues={initialValues}
        validationSchema={isFull() ? FormFullSchema : FormShortSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <h6 className="mb-4 fw-bold">Ерөнхий мэдээлэл</h6>
            <input id="image" name="image" hidden />
            <ImageUploader
              handleImage={(image) => {
                formik.setFieldValue("image", image);
              }}
              submitCount={formik.submitCount}
              errors={formik.errors}
            />
            <div className="row">
              <div className="col-12 col-md-6">
                <FormField field="lastName" label="Овог" />
              </div>
              <div className="col-12 col-md-6">
                <FormField field="firstName" label="Нэр" />
              </div>
              <div className="col-12 col-md-6">
                <FormField field="registerNumber" label="Регистрийн дугаар" />
              </div>
              <div className="col-12 col-md-6">
                <FormField field="phoneNumber" label="Утасны дугаар" />
              </div>
              <div className="col-12 col-md-6">
                <FormField field="email" label="И-мэйл хаяг" />
              </div>
              <div className="col-12 col-md-6">
                <FormField field="social" label="Facebook | Instagram" />
              </div>
              <div className="col-12">
                <FormField field="address" label="Гэрийн хаяг" as="textarea" />
              </div>
              <div className="col-12 mb-4 mt-n1 small text-secondary">
                <i>Дараах 3 талбарыг бөглөхгүй байж болно.</i>
              </div>
              <div className="col-12 col-md-4">
                <FormField field="gender" label="Хүйс" as="select">
                  <option value="">Сонгох</option>
                  <option value="Эрэгтэй">Эрэгтэй</option>
                  <option value="Эмэгтэй">Эмэгтэй</option>
                </FormField>
              </div>
              <div className="col-12 col-md-4">
                <FormField field="height" label="Өндөр" />
              </div>
              <div className="col-12 col-md-4">
                <FormField field="weight" label="Жин" />
              </div>
            </div>
            {isFull() && (
              <>
                <h6 className="mt-2 mb-4 fw-bold">Миний тухай</h6>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <FormField field="yasUndes" label="Яс үндэс" />
                  </div>
                  <div className="col-12 col-md-4">
                    <FormField field="tursunGazar" label="Төрсөн газар" />
                  </div>
                  <div className="col-12 col-md-4">
                    <FormField field="ussunGazar" label="Өссөн газар" />
                  </div>
                  <div className="col-12 col-md-4">
                    <FormField
                      field="heniiGarDeer"
                      label="Хэний гар дээр өссөн бэ?"
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <FormField
                      field="heduulee"
                      label="Эцэг эхээс хэдүүлээ вэ?"
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <FormField
                      field="heddehHuuhed"
                      label="Айлын хэд дэх хүүхэд вэ?"
                    />
                  </div>
                  <div className="col-12">
                    <FormField
                      field="zorilgo"
                      label="Таны зорилго"
                      as="textarea"
                      desc="Ирээдүйн зорилго тэмүүллийнхээ талаар бичнэ үү"
                    />
                  </div>
                  <div className="col-12">
                    <FormField
                      field="zarchim"
                      label="Баримталдаг зарчим"
                      as="textarea"
                      desc="Амьдралдаа мөрдлөг болгодог зарчим? Яагаад?"
                    />
                  </div>
                  <div className="col-12">
                    <FormField
                      field="baharhal"
                      label="Бахархдаг зүйлс"
                      as="textarea"
                      desc="Бахархдаг зүйлс? Яагаад?"
                    />
                  </div>
                  <div className="col-12">
                    <FormField
                      field="sainZan"
                      label="Зан чанарын сайн талууд"
                      as="textarea"
                    />
                  </div>
                  <div className="col-12">
                    <FormField
                      field="sulZan"
                      label="Зан чанарын сул талууд"
                      as="textarea"
                    />
                  </div>
                  <div className="col-12">
                    <FormField
                      field="hiihDurtai"
                      label="Хийх дуртай ажлууд"
                      as="textarea"
                    />
                  </div>
                  <div className="col-12">
                    <FormField
                      field="hiihDurgui"
                      label="Хийх дургүй ажлууд"
                      as="textarea"
                    />
                  </div>
                  <div className="col-12">
                    <FormField
                      field="hamgiinSainHiideg"
                      label="Хамгийн сайн хийж чаддаг ажлууд"
                      as="textarea"
                      desc="Бусдаас илүү сайн хийж чаддаг ажлууд?"
                    />
                  </div>
                  <div className="col-12">
                    <FormField
                      field="hobby"
                      label="Сонирхдог зүйлс / Хобби"
                      as="textarea"
                      desc="Амралт чөлөөт цагаар хийдэг зүйлс? Ном унших, шатар тоглох, аялах, урлаг спортоор хичээллэх..."
                    />
                  </div>
                </div>
                <h6 className="mb-4">Гэр бүлийн гишүүд</h6>
                <FieldArray
                  name="family"
                  render={(helper) => (
                    <div className="row">
                      {formik.values.family.map((member, index) => (
                        <div className="col-12" key={index}>
                          {formik.values.family.length > 1 && (
                            <div className="text-secondary mb-4 d-flex justify-content-between">
                              <small>{`Гишүүн ${index + 1}`}</small>
                              <span
                                className="small text-primary text-decoration-none cursor-pointer"
                                onClick={() => helper.remove(index)}
                              >
                                Устгах
                              </span>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`family[${index}].taniiYu`}
                                label="Таны юу болох?"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`family[${index}].ovogNer`}
                                label="Овог, Нэр"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`family[${index}].ajilMergejil`}
                                label="Ажил, мэргэжил"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`family[${index}].ajliinGazar`}
                                label="Ажлын газар"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`family[${index}].utas`}
                                label="Утас"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-12 col-md-4 mb-4">
                        <button
                          className="form-control btn btn-secondary"
                          onClick={() => {
                            helper.insert(formik.values.family.length, {
                              taniiYu: "",
                              ovogNer: "",
                              ajilMergejil: "",
                              ajliinGazar: "",
                              utas: "",
                            });
                          }}
                        >
                          Нэмэх
                        </button>
                      </div>
                    </div>
                  )}
                />
                <h6 className="mt-2 mb-1 fw-bold">
                  Төгссөн сургууль, эзэмшсэн мэргэжил
                </h6>
                <div className="mb-4">
                  <small className="text-secondary">
                    Суралцаж төгссөн сургууль, эзэмшсэн мэргэжлээ он цаг
                    хугацааны дэс дарааллын дагуу бичнэ үү.
                  </small>
                </div>
                <FieldArray
                  name="education"
                  render={(helper) => (
                    <div className="row">
                      {formik.values.education.map((school, index) => (
                        <div className="col-12" key={index}>
                          {formik.values.education.length > 1 && (
                            <div className="text-secondary mb-4 d-flex justify-content-between">
                              <small>{`Сургууль ${index + 1}`}</small>
                              <span
                                className="small text-primary text-decoration-none cursor-pointer"
                                onClick={() => helper.remove(index)}
                              >
                                Устгах
                              </span>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`education[${index}].surguuli`}
                                label="Сургууль"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`education[${index}].mergejil`}
                                label="Мэргэжил"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`education[${index}].zereg`}
                                label="Зэрэг, Цол"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`education[${index}].dungiinGolch`}
                                label="Дүнгийн голч"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`education[${index}].elssenOgnoo`}
                                label="Элссэн огноо"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`education[${index}].tugssunOgnoo`}
                                label="Төгссөн огноо"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-12 col-md-4 mb-4">
                        <button
                          className="form-control btn btn-secondary"
                          onClick={() => {
                            helper.insert(formik.values.education.length, {
                              surguuli: "",
                              mergejil: "",
                              zereg: "",
                              dungiinGolch: "",
                              elssenOgnoo: "",
                              tugssunOgnoo: "",
                            });
                          }}
                        >
                          Нэмэх
                        </button>
                      </div>
                    </div>
                  )}
                />
                <h6 className="mt-2 mb-1 fw-bold">Ажлын туршлага</h6>
                <div className="mb-4">
                  <small className="text-secondary">
                    Ажиллаж байсан байгууллага, эрхэлж байсан албан тушаалаа он
                    цаг хугацааны дэс дарааллын дагуу бичнэ үү.
                  </small>
                </div>
                <FieldArray
                  name="jobExps"
                  render={(helper) => (
                    <div className="row">
                      {formik.values.jobExps.map((jobExp, index) => (
                        <div className="col-12" key={index}>
                          {formik.values.jobExps.length > 1 && (
                            <div className="text-secondary mb-4 d-flex justify-content-between">
                              <small>{`Ажлын туршлага ${index + 1}`}</small>
                              <span
                                className="small text-primary text-decoration-none cursor-pointer"
                                onClick={() => helper.remove(index)}
                              >
                                Устгах
                              </span>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`jobExps[${index}].companiiNer`}
                                label="Байгууллагын нэр"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`jobExps[${index}].albanTushaal`}
                                label="Албан тушаал"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`jobExps[${index}].garsanShaltgaan`}
                                label="Ажлаас гарсан шалтгаан?"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`jobExps[${index}].orsonOgnoo`}
                                label="Орсон огноо"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`jobExps[${index}].garsanOgnoo`}
                                label="Гарсан огноо"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-12 col-md-4 mb-4">
                        <button
                          className="form-control btn btn-secondary"
                          onClick={() => {
                            helper.insert(formik.values.jobExps.length, {
                              companiiNer: "",
                              albanTushaal: "",
                              garsanShaltgaan: "",
                              orsonOgnoo: "",
                              garsanOgnoo: "",
                            });
                          }}
                        >
                          Нэмэх
                        </button>
                      </div>
                    </div>
                  )}
                />
                <h6 className="mt-2 mb-4 fw-bold">
                  Таны ажил байдлыг тодорхойлж чадах 3 хүнийг бичнэ үү
                </h6>
                <div className="row">
                  {formik.values.determinant.map((determinant, index) => (
                    <div className="col-12" key={index}>
                      <div className="text-secondary mb-4 d-flex justify-content-between">
                        <small>{`${index + 1}-р хүн`}</small>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <FormField
                            field={`determinant[${index}].ovogNer`}
                            label="Овог, Нэр"
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <FormField
                            field={`determinant[${index}].albanBaiguullaga`}
                            label="Албан байгууллага"
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <FormField
                            field={`determinant[${index}].albanTushaal`}
                            label="Албан тушаал"
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <FormField
                            field={`determinant[${index}].holbooBarihUtas`}
                            label="Холбоо барих утас"
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <FormField
                            field={`determinant[${index}].hezeeneesMedeh`}
                            label="Таныг хэзээнээс мэдэх вэ?"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <h6 className="mt-2 mb-4 fw-bold">
                  Бльюмон Групп-д ажилладаг танил байдаг бол бичнэ
                </h6>
                <FieldArray
                  name="bluemons"
                  render={(helper) => (
                    <div className="row">
                      {formik.values.bluemons.map((bluemon, index) => (
                        <div className="col-12" key={index}>
                          {formik.values.bluemons.length > 1 && (
                            <div className="text-secondary mb-4 d-flex justify-content-between">
                              <small>{`Танил ${index + 1}`}</small>
                              <span
                                className="small text-primary text-decoration-none cursor-pointer"
                                onClick={() => helper.remove(index)}
                              >
                                Устгах
                              </span>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`bluemons[${index}].ovogNer`}
                                label="Овог, Нэр"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`bluemons[${index}].company`}
                                label="Компани"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`bluemons[${index}].albanTushaal`}
                                label="Албан тушаал"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`bluemons[${index}].holbooBarihUtas`}
                                label="Холбоо барих утас"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <FormField
                                field={`bluemons[${index}].taniiYu`}
                                label="Таны юу болох"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-12 col-md-4 mb-4">
                        <button
                          className="form-control btn btn-secondary"
                          onClick={() => {
                            helper.insert(formik.values.bluemons.length, {
                              ovogNer: "",
                              company: "",
                              albanTushaal: "",
                              holbooBarihUtas: "",
                              taniiYu: "",
                            });
                          }}
                        >
                          Нэмэх
                        </button>
                      </div>
                    </div>
                  )}
                />
              </>
            )}
            <div className="btn-container">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Илгээх
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Step2;
