export const companies = [
  {
    order: 1,
    slug: "tse",
    name: "Tse",
    jobs: [
      {
        position: "Тогооч",
        salary: "2,000,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-00:00",
        workDays: "15",
        workDescription: "1 өдөр гараад 1 өдөр амарна",
        bonus: ["Унааны мөнгө", "Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Туслах тогооч",
        salary: "1,800,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-00:00",
        workDays: "15",
        workDescription: "1 өдөр гараад 1 өдөр амарна",
        bonus: ["Унааны мөнгө", "Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Угаагч",
        salary: "1,900,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-02:00",
        workDays: "15",
        workDescription: "1 өдөр гараад 1 өдөр амарна",
        bonus: ["Унааны мөнгө", "Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Үйлчлэгч",
        salary: "1,800,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-02:00",
        workDays: "15",
        workDescription: "1 өдөр гараад 1 өдөр амарна",
        bonus: ["Унааны мөнгө", "Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Зөөгч",
        salary: "1,900,000",
        jobTypes: ["Үндсэн", "Цагийн"],
        workHours: "09:00-18:00 / 18:00-03:00",
        workDays: "20",
        workDescription: "2 өдөр гараад 1 өдөр амарна",
        bonus: ["Унааны мөнгө", "Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Бэлтгэл цех-Бэлтгэгч",
        salary: "1,900,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-18:00",
        workDays: "26",
        workDescription: "Бүтэнсайн өдөр амардаг",
        bonus: ["Өдрийн 3 хоол"],
        enable: true,
      },
    ],
  },
  {
    order: 2,
    slug: "thebull",
    name: "The bull",
    jobs: [
      {
        position: "Үндсэн тогооч",
        salary: "2,500,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-22:00",
        workDays: "15",
        workDescription: "1 өдөр гараад 1 өдөр амарна.",
        bonus: [
          "Өдрийн 2 хоол",
          "2 цай(өглөө, үдээс хойш)",
        ],
        enable: true,
      },
      {
        position: "Бэлтгэл тогооч",
        salary: "2,000,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-22:00",
        workDays: "15",
        workDescription: "1 өдөр гараад 1 өдөр амарна.",
        bonus: [
          "Өдрийн 2 хоол",
          "2 цай(өглөө, үдээс хойш)",
        ],
        enable: true,
      },
      {
        position: "Зөөгч",
        salary: "1,800,000",
        jobTypes: ["Үндсэн", "Цагийн"],
        workHours: "09:00-18:00 / 13:00-22:00 / 15:00-00:00",
        workDays: "23",
        workDescription: "3 өдөр гараад 1 өдөр амарна.",
        bonus: [
          "Өдрийн 2 хоол",
          "2 цай(өглөө, үдээс хойш)",
          "Унааны мөнгө",
        ],
        enable: true,
      },
      {
        position: "Угаагч",
        salary: "2,000,000",
        jobTypes: ["Үндсэн"],
        workHours: "12:00-01:00",
        workDays: "15",
        workDescription: "1 өдөр гараад 1 өдөр амарна.",
        bonus: [
          "Өдрийн 2 хоол",
          "2 цай(өглөө, үдээс хойш)",
          "Унааны мөнгө",
        ],
        enable: true,
      },
      {
        position: "Үйлчлэгч",
        salary: "1,800,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-18:00 / 13:00-22:00",
        workDays: "23",
        workDescription: "3 өдөр гараад 1 өдөр амарна.",
        bonus: [
          "Өдрийн 2 хоол",
          "2 цай(өглөө, үдээс хойш)",
        ],
        enable: true,
      },
      {
        position: "Мах бэлтгэгч",
        salary: "2,000,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-18:00",
        workDays: "22",
        workDescription: "Ажлын 5 өдөр ажиллана.",
        bonus: [
          "Өдрийн хоол",
        ],
        enable: true,
      },
    ],
  },
  {
    order: 3,
    slug: "shulundu",
    name: "Shulundu",
    jobs: [
      {
        position: "Ээлжийн менежер",
        salary: "2,500,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-20:00 / 20:00-09:00",
        workDays: "20",
        workDescription: "12 цаг ажиллаад 24 цаг амарна",
        bonus: ["Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Зөөгч",
        salary: "1,900,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-20:00 / 20:00-09:00",
        workDays: "20",
        workDescription: "12 цаг ажиллаад 24 цаг амарна",
        bonus: ["Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Үйлчлэгч",
        salary: "1,800,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-20:00 / 20:00-09:00",
        workDays: "20",
        workDescription: "12 цаг ажиллаад 24 цаг амарна",
        bonus: ["Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Тогооч",
        salary: "2,500,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-20:00 / 20:00-09:00",
        workDays: "20",
        workDescription: "12 цаг ажиллаад 24 цаг амарна",
        bonus: ["Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Бэлтгэгч",
        salary: "2,000,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-20:00 / 20:00-09:00",
        workDays: "20",
        workDescription: "12 цаг ажиллаад 24 цаг амарна",
        bonus: ["Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Угаагч",
        salary: "1,900,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-20:00 / 20:00-09:00",
        workDays: "20",
        workDescription: "12 цаг ажиллаад 24 цаг амарна",
        bonus: ["Өдрийн 3 хоол"],
        enable: true,
      },
    ],
  },
  {
    order: 4,
    slug: "sura",
    name: "Sura",
    jobs: [
      {
        position: "Угтагч",
        salary: "2,000,000",
        jobTypes: ["Үндсэн"],
        workHours: "11:00-21:00",
        workDays: "24",
        workDescription: "Ажлын 5 өдөр",
        bonus: ["Өдрийн 3 хоол", "Унааны мөнгө"],
        enable: true,
      },
      {
        position: "Зөөгч",
        salary: "1,800,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-17:00 / 12:00-20:00 / 17:00-01:00",
        workDays: "24",
        workDescription: "3 өдөр ажиллаад 1 өдөр амарна",
        bonus: ["Өдрийн 3 хоол", "Унааны мөнгө"],
        enable: true,
      },
      {
        position: "Үйлчлэгч",
        salary: "1,800,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-21:00",
        workDays: "15",
        workDescription: "1 өдөр ажиллаад 1 өдөр амарна ",
        bonus: ["Өдрийн 3 хоол", "Унааны мөнгө"],
        enable: true,
      },
      {
        position: "Угаагч",
        salary: "1,900,000",
        jobTypes: ["Үндсэн"],
        workHours: "10:00-18:00 / 13:00-21:00 / 17:00-01:00",
        workDays: "24",
        workDescription: "3 өдөр ажиллаад 1 өдөр амарна",
        bonus: ["Өдрийн 3 хоол", "Унааны мөнгө"],
        enable: true,
      },
      {
        position: "Бэлтгэл тогооч",
        salary: "2,000,000",
        jobTypes: ["Үндсэн"],
        workHours: "10:00-22:00",
        workDays: "15",
        workDescription: "1 өдөр ажиллаад 1 өдөр амарна",
        bonus: ["Өдрийн 3 хоол", "Унааны мөнгө"],
        enable: true,
      },
      {
        position: "Үндсэн хоолны тогооч",
        salary: "2,500,000",
        jobTypes: ["Үндсэн"],
        workHours: "10:00-22:00",
        workDays: "15",
        workDescription: "1 өдөр ажиллаад 1 өдөр амарна",
        bonus: ["Өдрийн 3 хоол", "Унааны мөнгө"],
        enable: true,
      },
    ],
  },
  {
    order: 5,
    slug: "buuzger",
    name: "Buuz ger",
    jobs: [
      {
        position: "Ээлжний тогооч",
        salary: "2,500,000",
        jobTypes: ["Үндсэн"],
        workHours: "16 цаг",
        workDays: "15",
        workDescription: "1 өдөр гараад 1 өдөр амарна.",
        bonus: ["Өдрийн 3 хоол", "Унааны мөнгө"],
        enable: true,
      },
      {
        position: "Туслах тогооч",
        salary: "2,000,000",
        jobTypes: ["Үндсэн"],
        workHours: "10:00-20:00",
        workDays: "22",
        workDescription: "Ажлын 5 өдөр ажиллана.",
        bonus: ["Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Менежер",
        salary: "2,500,000",
        jobTypes: ["Үндсэн"],
        workHours: "16 цаг",
        workDays: "15",
        workDescription: "1 өдөр гараад 1 өдөр амарна.",
        bonus: ["Өдрийн 3 хоол", "Унааны мөнгө"],
        enable: true,
      },
      {
        position: "Зөөгч",
        salary: "1,900,000",
        jobTypes: ["Үндсэн"],
        workHours: "10:00-20:00",
        workDays: "22",
        workDescription: "Ажлын 5 өдөр ажиллана.",
        bonus: ["Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Бэлтгэлийн цэх - Бэлтгэгч",
        salary: "2,000,000",
        jobTypes: ["Үндсэн"],
        workHours: "08:00-17:00",
        workDays: "22",
        workDescription: "7 хоногт 5 өдөр ажиллана.",
        bonus: ["Өдрийн 3 хоол"],
        enable: true,
      },
      {
        position: "Угаагч",
        salary: "1,900,000",
        jobTypes: ["Үндсэн"],
        workHours: "16 цаг",
        workDays: "15",
        workDescription: "1 өдөр гараад 1 өдөр амарна.",
        bonus: ["Өдрийн 3 хоол", "Унааны мөнгө"],
        enable: true,
      },
      {
        position: "Үйлчлэгч",
        salary: "1,800,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-19:00",
        workDays: "22",
        workDescription: "Ажлын 5 өдөр ажиллана.",
        bonus: ["Өдрийн 3 хоол"],
        enable: true,
      },
    ],
  },
  {
    order: 6,
    slug: "turelt",
    name: "Turelt",
    jobs: [
      {
        position: "Ресепшин",
        salary: "1,300,000-1,500,000",
        jobTypes: ["Үндсэн"],
        workHours: "08:00-16:00 / 16:00-00:00",
        workDays: "20",
        workDescription: "2 өдөр гараад 1 өдөр амарна.",
        bonus: ["Өглөөний цай, Өдрийн хоол", "Унааны мөнгө"],
        enable: true,
      },
      {
        position: "Хамгаалагч",
        salary: "1,300,000",
        jobTypes: ["Үндсэн"],
        workHours: "24 цаг",
        workDays: "10",
        workDescription: "1 өдөр гараад 2 өдөр амарна.",
        bonus: ["Өдрийн 3 хоол", "Унааны мөнгө"],
        enable: true,
      },
      {
        position: "Зогсоолын ажилтан",
        salary: "1,300,000-1,500,000",
        jobTypes: ["Үндсэн"],
        workHours: "09:00-18:00 / 10:00-19:00 / 11:00-20:00",
        workDays: "22",
        workDescription: "Ажлын 5 өдөр ажиллана.",
        bonus: ["Өглөөний цай, Өдрийн хоол"],
        enable: true,
      },
      {
        position: "Үйлчлэгч",
        salary: "1,300,000",
        jobTypes: ["Үндсэн"],
        workHours: "08:00-17:00",
        workDays: "22",
        workDescription: "Ажлын 5 өдөр ажиллана.",
        bonus: ["Өглөөний цай, Өдрийн хоол"],
        enable: true,
      },
    ],
  },
  {
    order: 7,
    slug: "nike",
    name: "Nike",
    jobs: [
      {
        position: "Худалдааны зөвлөх",
        salary: "1,800,000",
        jobTypes: ["Үндсэн"],
        workHours: "6-10 цаг",
        workDays: "22-24",
        workDescription: "176-180 цаг ажиллана.",
        bonus: ["Урамшуулалт цалин", "Хоолны мөнгө"],
        enable: true,
      },
    ],
  },
  {
    order: 8,
    slug: "converse",
    name: "Converse",
    jobs: [
      {
        position: "Худалдааны зөвлөх",
        salary: "1,800,000",
        jobTypes: ["Үндсэн"],
        workHours: "6-10 цаг",
        workDays: "24",
        workDescription: "2 хагас 2 бүтэн гараа, 1 амралт (хагас гараа 6 цаг, бүтэн гараа 10 цаг), Бүх салбарт ажиллах боломжтой байх",
        bonus: ["Борлуулалтын урамшуулал", "Хоолны мөнгө", "CONVERSE брэндийн гутал, хувцас хангамж", "БльюМон группийн бусад нэмэгдлүүд"],
        enable: false,
      },
      // {
      //   position: "Маркетингийн дадлагажигч",
      //   salary: "",
      //   jobTypes: ["Үндсэн"],
      //   workHours: "6 цаг",
      //   workDays: "15",
      //   workDescription: "Маркетинг-р суралцаж байгаа оюутнууд хамрагдах боломжтой, converse брэндэд дуртай, PS дээр ажиллах анхан шатны мэдлэгтэй, сошиал хуудсуудад ажиллах, reel хийх сонирхолтой, идэвхитэй",
      //   bonus: [],
      //   enable: false,
      // },
      {
        position: "Худалдааны зөвлөх",
        salary: "50,000",
        jobTypes: ["Цагийн"],
        workHours: "12:00-21:00",
        workDays: "12",
        workDescription: "Баасан, Бямба, Ням-д ажиллана.",
        bonus: ["Борлуулалтын урамшуулал", "Хоолны мөнгө"],
        enable: false,
      },
    ],
  },
  {
    order: 9,
    slug: "vans",
    name: "Vans",
    jobs: [
      {
        position: "Маркетинг менежер",
        salary: "4,000,000+",
        jobTypes: ["Үндсэн"],
        workHours: "8 цаг",
        workDays: "20",
        workDescription: "Ажлын онцлог:\n- Чөлөөт гутал, хувцасны дэлхийн тэргүүлэгч брэндийн нэг хэсэг болох\n- Өөрийн карьерийг хөгжүүлэх өвөрмөц онцлогтой\n- Монгол болон Олон улсын харилцаа хослуулсан сонирхолтой, сорил бүхий ажлын соёл\n\nТавигдах шаардлага: \n- Бизнесийн удирдлага, маркетинг, гадаад худалдааны чиглэлээр их, дээд сургууль төгссөн \n- Бүтээлч сэтгэлгээтэй - Дижитал маркетинг сувгуудыг ашиглах чадвартай \n- Орчин үеийн залуусын соёл, хөгжим, урлаг, загварын чиг хандлагын талаар мэдлэгтэй \n- Харилцааны өндөр чадвартай, багийн ажиллагааг эрхэмлэж ажилладаг \n- Нөхцөл байдалд анализ хийж зөв шийдвэр гаргах, дүн шинжилгээ хийх \n- Англи хэлний бичгийн болон ярианы өндөр чадвартай \n\nНэмэлт мэдээлэл\n- Гадаадад их дээд сургууль төгссөн, Маркетингийн чиглэлээр ажиллаж байсан туршлагатай бол давуу тал болно. ",
        bonus: ["Борлуулалтын урамшуулал"],
        enable: false,
      },
      {
        position: "Дэлгүүрийн менежер",
        salary: "2,000,000",
        jobTypes: ["Үндсэн"],
        workHours: "6-10 цаг",
        workDays: "24",
        workDescription: "2 хагас 2 бүтэн гараа, 1 амралт (хагас гараа 6 цаг, бүтэн гараа 10 цаг), Бүх салбарт ажиллах боломжтой байх",
        bonus: ["Борлуулалтын урамшуулал", "Хоолны мөнгө", "VANS брэндийн гутал, хувцас хангамж", "БльюМон группийн бусад нэмэгдлүүд"],
        enable: false,
      },
      {
        position: "Худалдааны зөвлөх",
        salary: "1,800,000",
        jobTypes: ["Үндсэн"],
        workHours: "6-10 цаг",
        workDays: "24",
        workDescription: "2 хагас 2 бүтэн гараа, 1 амралт (хагас гараа 6 цаг, бүтэн гараа 10 цаг), Бүх салбарт ажиллах боломжтой байх",
        bonus: ["Борлуулалтын урамшуулал", "Хоолны мөнгө", "VANS брэндийн гутал, хувцас хангамж", "БльюМон группийн бусад нэмэгдлүүд"],
        enable: false,
      },
      {
        position: "Худалдааны зөвлөх",
        salary: "50,000",
        jobTypes: ["Цагийн"],
        workHours: "12:00-21:00",
        workDays: "12",
        workDescription: "Баасан, Бямба, Ням-д ажиллана.",
        bonus: ["Борлуулалтын урамшуулал", "Хоолны мөнгө"],
        enable: false,
      },
    ],
  },
];

export const jobTypes = ["Үндсэн", "Цагийн"];
