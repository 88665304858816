import { BrowserRouter, Routes, Route } from "react-router-dom";
import Form from "./pages/form/form";
import Login from "./pages/login/login";
import AnketList from "./pages/anket/list";
import Anket from "./pages/anket/anket";
import { companies } from "./data/data";

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Form />} />
          {companies.map((company) => (
            <Route
              key={company.slug}
              path={`/${company.slug}`}
              element={<Form slug={company.slug} />}
            />
          ))}
          <Route path="/step/:step" element={<Form />} />
          <Route path="/login" element={<Login />} />
          <Route path="/anket" element={<AnketList />} />
          <Route path="/anket/:id" element={<Anket />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
