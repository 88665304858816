import React from "react";

const FormLayout = ({ children }) => {
  return (
    <>
      <div className="header">
        <img
          src={process.env.PUBLIC_URL + "/images/logo.png"}
          alt="Гоё ажилд орох амархан"
          title="Гоё ажилд орох амархан"
        />
      </div>
      <div className="main-container">{children}</div>
    </>
  );
};

export default FormLayout;
