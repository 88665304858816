import { ErrorMessage, Field } from "formik";

const FormField = ({ type = "text", field, label, as, desc, children }) => {
  return (
    <div className="input-container">
      {children ? (
        <Field name={field} as={as || "input"} className="input">
          {children}
        </Field>
      ) : (
        <Field type={type} name={field} as={as || "input"} className="input" />
      )}

      <div className="label">
        <div className="text">{label}</div>
      </div>
      {desc && <div className="desc">{desc}</div>}
      <div className="error">
        <ErrorMessage name={field} />
      </div>
    </div>
  );
};

export default FormField;
