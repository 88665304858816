import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Margin, usePDF } from "react-to-pdf";
import { anket } from "../../utils/get";
import { onImageError } from "../../utils/imageUtil";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Anket = () => {
  const { id } = useParams();
  const [aboutMe, setAboutMe] = useState([]);
  const [others, setOthers] = useState([]);
  const navigate = useNavigate();
  let query = useQuery();
  const { toPDF, targetRef } = usePDF({
    filename: `${query.get("name")}.pdf`,
    page: { margin: Margin.NONE },
    overrides: {
      canvas: {
        useCORS: true,
        imageTimeout: 10000,
      },
    },
  });

  useEffect(() => {
    anket(id, navigate).then((data) => {
      if (data && data.length > 0) {
        setAboutMe({
          ...data[0][0],
          emp_chestpic: data[0][0].emp_chestpic ? data[0][0].emp_chestpic.replace(":8089/P", "/p") : ''
        });
        setOthers({
          family: data[3],
          schools: data[2],
          jobs: data[4],
          persons: data[1],
          bluemons: data[5],
        });
      }
    });
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="anket">
        <button
          className="btn btn-secondary me-3"
          onClick={() => {
            navigate("/anket");
          }}
        >
          Буцах
        </button>
        <button className="btn btn-secondary" onClick={toPDF}>
          PDF татах
        </button>
      </div>
      {/* <div className="d-flex align-center justify-content-center mt-4"></div> */}
      <div className="anket" ref={targetRef}>
        <div className="a4">
          <div className="anket-header">
            <img
              src={process.env.PUBLIC_URL + "/images/bluemon-logo.png"}
              alt="Гоё ажилд орох амархан"
              title="Гоё ажилд орох амархан"
              height={"30"}
            />
            <div className="small">
              Огноо:{" "}
              {aboutMe.create_date &&
                format(
                  new Date(aboutMe.create_date.replace("T", " ")),
                  "yyyy-MM-dd HH:mm:ss"
                )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 mb-2">
              <small className="text-secondary">Компани</small>
              <div className="text-break">{aboutMe.company || "-"}</div>
            </div>
            <div className="col-12 col-md-4 mb-2">
              <small className="text-secondary">Ажиллах цагийн төрөл</small>
              <div className="text-break">{aboutMe.emp_work_type || "-"}</div>
            </div>
            <div className="col-12 col-md-4 mb-2">
              <small className="text-secondary">Сонирхож буй ажлын байр</small>
              <div className="text-break">
                {aboutMe.emp_work_position || "-"}
              </div>
            </div>
          </div>
          <div className="border-bottom border-2 mb-3 mt-2"></div>
          <div className="row">
            <div className="col-12 col-md-3 mb-2">
              <img
                src={aboutMe.emp_chestpic}
                alt={aboutMe.emp_firstname}
                // className="w-100"
                style={{ maxHeight: "350px", maxWidth: "100%" }}
                onError={(e) => {
                  onImageError(e, aboutMe.emp_sex);
                }}
              />
            </div>
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-12 col-md-6 mb-2">
                  <small className="text-secondary">Овог</small>
                  <div className="text-break">
                    {aboutMe.emp_lastname || "-"}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <small className="text-secondary">Нэр</small>
                  <div className="text-break">
                    {aboutMe.emp_firstname || "-"}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <small className="text-secondary">Регистрийн дугаар</small>
                  <div className="text-break">
                    {aboutMe.emp_register || "-"}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <small className="text-secondary">Утасны дугаар</small>
                  <div className="text-break">{aboutMe.emp_phone || "-"}</div>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <small className="text-secondary">И-мэйл хаяг</small>
                  <div className="text-break">{aboutMe.emp_email || "-"}</div>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <small className="text-secondary ">
                    Facebook, Twitter, Instagram
                  </small>
                  <div className="text-break">{aboutMe.emp_social || "-"}</div>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <small className="text-secondary ">Гэрийн хаяг</small>
                  <div className="text-break">{aboutMe.emp_address || "-"}</div>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <small className="text-secondary">Хүйс</small>
                  <div className="text-break">{aboutMe.emp_sex || "-"}</div>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <small className="text-secondary">Биеийн өндөр</small>
                  <div className="text-break">{aboutMe.emp_height || "-"}</div>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <small className="text-secondary">Биеийн жин</small>
                  <div className="text-break">{aboutMe.emp_weight || "-"}</div>
                </div>
              </div>
            </div>
          </div>
          {(aboutMe.company === "nike" ||
            aboutMe.company === "vans" ||
            aboutMe.company === "converse" ||
            aboutMe.company === "turelt") && (
            <>
              <div className="row mt-3" style={{ pageBreakAfter: "always" }}>
                <div className="col-12">
                  <div className="pb-1 border-bottom border-2 mb-2">
                    <h5>Миний тухай</h5>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  <small className="text-secondary">Яс үндэс</small>
                  <div className="text-break">
                    {aboutMe.emp_yas_undes || "-"}
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  <small className="text-secondary">Төрсөн газар</small>
                  <div className="text-break">
                    {aboutMe.emp_tursun_gazar || "-"}
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  <small className="text-secondary">Өссөн газар</small>
                  <div className="text-break">
                    {aboutMe.emp_ussun_gazar || "-"}
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  <small className="text-secondary">
                    Хэний гар дээр өссөн бэ?
                  </small>
                  <div className="text-break">
                    {aboutMe.emp_henii_gar_deer || "-"}
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  <small className="text-secondary">
                    Эцэг эхээс хэдүүлээ вэ?
                  </small>
                  <div className="text-break">
                    {aboutMe.emp_heduulee || "-"}
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-2">
                  <small className="text-secondary">
                    Айлын хэд дэх хүүхэд вэ?
                  </small>
                  <div className="text-break">
                    {aboutMe.emp_heddeh_huuhed || "-"}
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <small className="text-secondary">Таны зорилго</small>
                  <div className="text-break">{aboutMe.emp_zorilgo || "-"}</div>
                </div>
                <div className="col-12 mb-2">
                  <small className="text-secondary">Баримталдаг зарчим</small>
                  <div className="text-break">{aboutMe.emp_zarchim || "-"}</div>
                </div>
                <div className="col-12 mb-2">
                  <small className="text-secondary">Бахархдаг зүйлс</small>
                  <div className="text-break">
                    {aboutMe.emp_baharhal || "-"}
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <small className="text-secondary">
                    Зан чанарын сайн талууд
                  </small>
                  <div className="text-break">
                    {aboutMe.emp_sain_zan || "-"}
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <small className="text-secondary">
                    Зан чанарын сул талууд
                  </small>
                  <div className="text-break">{aboutMe.emp_sul_zan || "-"}</div>
                </div>
                <div className="col-12 mb-2">
                  <small className="text-secondary">Хийх дуртай ажлууд</small>
                  <div className="text-break">
                    {aboutMe.emp_hiih_durtai || "-"}
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <small className="text-secondary">Хийх дургүй ажлууд</small>
                  <div className="text-break">
                    {aboutMe.emp_hiih_durgui || "-"}
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <small className="text-secondary">
                    Хамгийн сайн хийж чаддаг ажлууд
                  </small>
                  <div className="text-break">
                    {aboutMe.emp_hamgiin_sain_hiideg || "-"}
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <small className="text-secondary">
                    Сонирхдог зүйлс / Хобби
                  </small>
                  <div className="text-break">{aboutMe.emp_hobby || "-"}</div>
                </div>
              </div>
            </>
          )}
        </div>
        {(aboutMe.company === "nike" ||
          aboutMe.company === "vans" ||
          aboutMe.company === "converse" ||
          aboutMe.company === "turelt") && (
          <div className="a4">
            {others.family && others.family.length > 0 && (
              <div className="row" style={{ pageBreakBefore: "always" }}>
                <div className="col-12">
                  <div className="pb-1 border-bottom border-2 mb-2">
                    <h5>Гэр бүлийн гишүүд</h5>
                  </div>
                </div>
                {others.family.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Таны хэн болох?</small>
                      <div className="text-break">{item.tanii_yu || "-"}</div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Овог, Нэр</small>
                      <div className="text-break">{item.ovog_ner || "-"}</div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Ажил мэргэжил</small>
                      <div className="text-break">
                        {item.ajil_mergejil || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Ажлын газар</small>
                      <div className="text-break">
                        {item.ajliin_gazar || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Утас</small>
                      <div className="text-break">{item.utas || "-"}</div>
                    </div>
                    <div className="col-12">
                      <div className="border-bottom mb-2"></div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
            {others.schools && others.schools.length > 0 && (
              <div className="row mt-3">
                <div className="col-12">
                  <div className="pb-1 border-bottom border-2 mb-2">
                    <h5>Төгссөн сургууль, эзэмшсэн мэргэжил</h5>
                  </div>
                </div>
                {others.schools.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Сургууль</small>
                      <div className="text-break">{item.surguuli || "-"}</div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Мэргэжил</small>
                      <div className="text-break">{item.mergejil || "-"}</div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Зэрэг, цол</small>
                      <div className="text-break">{item.zereg || "-"}</div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Дүнгийн голч</small>
                      <div className="text-break">
                        {item.dungiin_golch || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Элссэн огноо</small>
                      <div className="text-break">
                        {item.elssen_ognoo || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Төгссөн огноо</small>
                      <div className="text-break">
                        {item.tugssun_ognoo || "-"}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="border-bottom mb-2"></div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
            {others.jobs && others.jobs.length > 0 && (
              <div className="row mt-3">
                <div className="col-12">
                  <div className="pb-1 border-bottom border-2 mb-2">
                    <h5>Ажлын туршлага</h5>
                  </div>
                </div>
                {others.jobs.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Байгууллагын нэр</small>
                      <div className="text-break">
                        {item.companii_ner || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Албан тушаал</small>
                      <div className="text-break">
                        {item.alban_tushaal || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary text-nowrap">
                        Гарсан шалтгаан
                      </small>
                      <div className="text-break">
                        {item.garsan_shaltgaan || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Орсон огноо</small>
                      <div className="text-break">
                        {item.orson_ognoo || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Гарсан огноо</small>
                      <div className="text-break">
                        {item.garsan_ognoo || "-"}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="border-bottom mb-2"></div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
            {others.persons && others.persons.length > 0 && (
              <div className="row mt-3">
                <div className="col-12">
                  <div className="pb-1 border-bottom border-2 mb-2">
                    <h5>
                      Таны ажил байдлыг тодорхойлж чадах 3 хүнийг бичнэ үү
                    </h5>
                  </div>
                </div>
                {others.persons.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Овог нэр</small>
                      <div className="text-break">{item.ovog_ner || "-"}</div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">
                        Албан байгууллага
                      </small>
                      <div className="text-break">
                        {item.alban_baiguullaga || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Албан тушаал</small>
                      <div className="text-break">
                        {item.alban_tushaal || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">
                        Холбоо барих утас
                      </small>
                      <div className="text-break">
                        {item.holboo_barih_utas || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 mb-2">
                      <small className="text-secondary">
                        Таныг хэзээнээс мэдэх вэ?
                      </small>
                      <div className="text-break">
                        {item.hezeenees_medeh || "-"}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="border-bottom mb-2"></div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
            {others.bluemons && others.bluemons.length > 0 && (
              <div className="row mt-3">
                <div className="col-12">
                  <div className="pb-1 border-bottom border-2 mb-2">
                    <h5>Бльюмон Групп-д ажилладаг танил байдаг бол бичнэ үү</h5>
                  </div>
                </div>
                {others.bluemons.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Овог нэр</small>
                      <div className="text-break">{item.ovog_ner || "-"}</div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Компани</small>
                      <div className="text-break">{item.company || "-"}</div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">Албан тушаал</small>
                      <div className="text-break">
                        {item.alban_tushaal || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-2 mb-2">
                      <small className="text-secondary">
                        Холбоо барих утас
                      </small>
                      <div className="text-break">
                        {item.holboo_barih_utas || "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 mb-2">
                      <small className="text-secondary">Таны юу болох</small>
                      <div className="text-break">{item.tanii_yu || "-"}</div>
                    </div>
                    <div className="col-12">
                      <div className="border-bottom mb-2"></div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Anket;
