import React from "react";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-4">
        <img
          src={process.env.PUBLIC_URL + "/images/bluemon-logo.png"}
          alt="Гоё ажилд орох амархан"
          title="Гоё ажилд орох амархан"
          height={"30"}
        />
        <div className="small">
          <span className="me-3">{`Сайн уу ${user && user.fullName}.`}</span>
          <span onClick={logout} className="cursor-pointer text-primary">
            Гарах
          </span>
        </div>
      </div>
      <div className="list-container">{children}</div>
      <div className="footer">Bluemon Co., Ltd</div>
    </>
  );
};

export default Layout;
