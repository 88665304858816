import axios from "axios";

const header = (navigate) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.log("token isn't found");
    navigate("/login");
  }
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const list = async (navigate) => {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/anket?company=${
        user && user.company ? user.company : ""
      }`,
      header(navigate)
    )
    .then((data) => {
      if (data.status === 200) {
        return data.data;
      }
    })
    .catch((error) => {
      console.log("get list", error);
      navigate("/login");
    });
};

export const anket = async (id, navigate) => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/anket/getbyid?id=${id}`,
      header(navigate)
    )
    .then((data) => {
      if (data.status === 200) {
        return data.data;
      }
    })
    .catch((error) => {
      console.log("get anket", error);
      navigate("/login");
    });
};
