const Success = () => {
  return (
    <div className="success-container">
      <img
        className="success-others"
        src={process.env.PUBLIC_URL + "/images/success-others.png"}
        alt="Гоё ажилд орох амархан"
        title="Гоё ажилд орох амархан"
      />
      <img
        className="success-desktop"
        src={process.env.PUBLIC_URL + "/images/success-desktop.png"}
        alt="Гоё ажилд орох амархан"
        title="Гоё ажилд орох амархан"
      />
    </div>
  );
};

export default Success;
