import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import FormField from "../../components/form/FormField";
import { useNavigate } from "react-router-dom";
import FormLayout from "../../components/FormLayout";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Нэвтрэх нэрээ оруулна уу"),
  password: Yup.string().required("Нууц үгээ оруулна уу"),
});

const Login = () => {
  const navigate = useNavigate();
  const initialValues = {
    username: "",
    password: "",
  };

  const onSubmit = (values) => {
    console.log(values);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/login`,
        JSON.stringify({
          UserName: values.username,
          password: values.password,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...response.data.userDetails,
            password: "",
          })
        );
        // console.log("data", localStorage);
        navigate("/anket");
      })
      .catch((error) => {
        console.log("Login error", error);
      });
  };

  return (
    <>
      <FormLayout>
        <div className="form-container">
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <h5 className="text-center mb-4 mt-4 fw-bold">Нэвтрэх</h5>
                <div className="row d-flex justify-content-center">
                  <div className="col-12 col-md-6">
                    <FormField field="username" label="Нэвтрэх нэр" />
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-12 col-md-6">
                    <FormField
                      type="password"
                      field="password"
                      label="Нууц үг"
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-12 col-md-6 btn-container">
                    <button
                      className="form-control btn btn-primary"
                      type="submit"
                    >
                      Нэвтрэх
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </FormLayout>
      <div className="footer">Bluemon Co., Ltd</div>
    </>
  );
};

export default Login;
